const styles = (theme) => {
  const { baseUnit } = theme;

  return {
    root: {
      paddingTop: baseUnit,
    },
  };
};

export default styles;
